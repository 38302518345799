@import "./../../styles/settings";

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $color-white;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  display: flex;

  &:focus-visible {
    outline: none;
    border: none;
  }

  &--content {
    position: relative;
    width: 100%;
    display: flex;

    &:focus-visible {
      outline: none;
      border: none;
    }

    &-area {
      overflow: auto;
      position: relative;
      width: 100%;
      margin-top: 80px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  &--close-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &--back-button {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  &--title {
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;
    position: absolute;
    padding: 10px;
    top: 20px;
    right: 0;
    left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
  }
}

.left-82 {
  left: 55px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(11, 73, 232, 0.2);
  z-index: 10000;
}

@include respondTo($md) {
  .custom-modal {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
    border-radius: 14px;
  }
}

@include respondTo($lg) {
  .custom-modal {
    max-width: 70%;

    &--close-button {
      top: 25px;
      right: 45px;
    }

    &--back-button {
      top: 25px;
      left: 45px;
    }

    &--title {
      top: 25px;
      left: 36px;
    }
  }

  .left-82 {
    left: 82px;
  }
}

@include respondTo($xl) {
  .custom-modal {
    max-width: 70%;
  }
}
