@import "./../../styles/settings";

.custom-button {
  display: flex;
  flex-direction: row;
  color: $color-white;
  height: 100%;
  align-items: center;
  justify-content: center;

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    top: 2px;
    position: relative;
  }

  &--text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
  }

  .with-icon {
    position: relative;
    top: 2px;
  }

  &--small {
    border-radius: 5px;
  }

  &--medium {
    padding: 0px 20px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 17px;
  }

  &--large {
    padding: 0px 30px;
    border-radius: 10px;
    font-weight: 900;
    font-size: 18px;
  }

  &--primary {
    background-color: $primary-color;

    &:disabled {
      background-color: lighten($gray, 5);
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background-color: lighten($primary-color, 10);
      color: $color-white;
    }
  }

  &--blue {
    background-color: $color-fb-blue;

    &:disabled {
      background-color: lighten($gray, 5);
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background-color: lighten($color-fb-blue, 10);
      color: $color-white;
    }
  }

  &--green {
    background-color: $color-green;

    &:disabled {
      background-color: lighten($gray, 5);
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background-color: lighten($color-green, 10);
      color: $color-white;
    }
  }

  &--yellow {
    background-color: $forth-color;

    &:disabled {
      background-color: lighten($gray, 5);
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background-color: lighten($forth-color, 10);
      color: $color-white;
    }
  }

  &--red {
    background-color: $color-red;

    &:disabled {
      background-color: lighten($gray, 5);
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background-color: lighten($color-red, 10);
      color: $color-white;
    }
  }

  &--black {
    background-color: $color-apply-gray;

    &:disabled {
      background-color: lighten($gray, 5);
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background-color: lighten($color-apply-gray, 10);
      color: $color-white;
    }
  }
}
