@import "./../../styles/settings";

.search-box-container {
  display: flex;
  width: 100%;
  border-radius: 14px;
  box-shadow: 0 0px 25px 0px rgba(0, 0, 0, 0.2);
  background-color: $color-white;
  flex-direction: column;
  min-height: 340px;
}

.tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;

  .tab {
    border-right: 1px solid $light;

    &:last-child {
      border-right: none;
    }
  }
}

.tab-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $light;
  border-radius: 0;
  transition: background-color 1s ease-out;
  padding: 10px 10px;

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light;
    width: 22px;
    height: 20px;
    margin-right: 0;
    position: relative;
    top: -1px;
    transition: background-color 1s ease-out;
  }

  &--title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    transition: all 1s ease-out;
  }

  &:focus {
    box-shadow: none;
  }
}

.tab-button--active {
  background-color: $primary-color;
  color: $color-white;

  .tab-button--title {
    color: $color-white;
  }
  .tab-button--icon {
    background-color: $color-white;
  }
}

.search-box--button {
  padding: 20px;
  width: 100%;
  min-height: 60px;
}

.button-text:hover {
  text-decoration: underline;
   color: rgb(11, 46, 242);
}
.multi-button {
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  color: $color-medium-gray;
}

/** Train ticket body styles */
.train-ticket-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding: 10px 0;

  .disable-via {
    pointer-events: none;
    opacity: 0.6;
  }

  .border-box {
    display: flex;
    padding: 10px 20px;
    border-radius: 14px;
    border: 1px solid $light;
    align-items: center;

    .dropdown {
      width: 75%;

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $color-medium-gray;
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
      }
    }

    .w-30 {
      width: 25%;
      margin-right: 3%;
      border-right: 1px solid $light;
    }
  }

  .radio-box {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin: 8px 0;
    width: auto;

    &--radio {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      padding: 2px;
      width: 15px;
      height: 15px;
      border: 2px solid $primary-color;
      border-radius: 100%;
      cursor: pointer;
    }

    .radio-label {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
  }

  .disable-radio {
    pointer-events: none;
    border-color: $color-gray;
  }

  .disable-active-radio {
    background-color: $color-gray !important;
  }

  .custom-label {
    color: $color-medium-gray;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
  }
}
/** .end train ticket body */

/** rail pass styles */
.rail-pass-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;

  .detail-box--aside {
    width: 50%;
  }
}
/** .end rail pass styles */

/**  blogs body styles */
.blogs-body {
  display: flex;
  padding: 15px 30px;
  margin-bottom: 15px;
  max-height: 100%;
  height: 340px;
  overflow-x: hidden;
  overflow-y: hidden;

  .blog-carousal--btn-left {
    position: absolute;
    padding: 12px 7px;
    left: -20px;
  }

  .blog-carousal--btn-right {
    position: absolute;
    padding: 12px 7px;
    right: -20px;
  }

  .blog-item--outer {
    flex-direction: column;
    overflow: hidden;
    min-height: 300px;
    flex: 1;
    padding: 10px;
  }

  .blog-item {
    border-radius: 10px;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    min-height: 300px;
    flex: 1;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);

    img {
      height: 130px;
    }

    &--title {
      font-weight: 800;
      font-size: 16px;
      line-height: 18px;
    }

    &--sub {
      color: $color-gray;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
    }

    &--desc {
      color: $color-gray;
      font-size: 13px;
      line-height: 16px;
    }

    &--icon {
      position: relative;
      top: -1px;
    }
  }

  .hover-effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba($color-white, 1) 0%, rgba($color-white, 1) 4%, rgba($color-white, 0) 20%);

    &::after {
      content: "Read More...";
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: $primary-color;
      font-size: 12px;
      line-height: 12px;
    }

    &:hover {
      background: linear-gradient(0deg, rgba($color-white, 1) 0%, rgba($color-white, 1) 10%, rgba($color-white, 0) 40%);

      &::after {
        content: "Read More...";
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: $primary-color;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}
/**  .end blogs body styles */

/** responsive styles */
@include respondTo($md) {
  .tab-button {
    height: 55px;
    flex-direction: row;

    &--icon {
      margin-right: 10px;
    }

    &--title {
      font-size: 16px;
      list-style: 18px;
      font-weight: 800;
    }
  }

  .blogs-body {
    .blog-item {
      max-height: 100%;
    }

    .hover-effect {
      background: transparent;

      &::after {
        content: "";
      }

      &:hover {
        background: linear-gradient(
          0deg,
          rgba($color-white, 1) 0%,
          rgba($color-white, 1) 10%,
          rgba($color-white, 0) 40%
        );

        &::after {
          content: "Read More...";
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: $primary-color;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}

@include respondTo($lg) {
  .search-box-container {
    min-height: 215px;
  }

  .train-ticket-body {
    padding: 20px 0;
  }

  .rail-pass-body {
    height: 143px;
  }
}
