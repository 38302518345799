@import './../../styles/settings';

.custom-toggle-button {
  display: flex;
  align-items: center;
  position: relative;
  width: 56px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 40px;
  background-color: $color-lighter-gray;
  cursor: pointer;

  &--slider {
    box-sizing: border-box;
    position: absolute;
    margin: 2px;
    height: 26px;
    width: 26px;
    background-color: $color-white;
    border-radius: 100%;
    transition: all 300ms ease-in-out;
    cursor: pointer;
  }

  .slider-active {
    transform: translateX(100%);
  }
}

.active-bg {
  background-color: $color-fb-blue;
}