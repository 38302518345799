@import "./../../styles/settings";

.calendar-dropdown {
  /* .classes for help dropdown animations */
  &-enter-active {
    opacity: 1;
    max-height: 100vh;
  }
  &-enter-done {
    opacity: 1;
    max-height: 100vh;
  }

  &--body {
    display: flex;
    justify-content: center;
    padding-top: 60px;
  }

  .DayPicker-Caption {
    text-align: center;
    position: absolute;
    top: 14px;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 0);

    div {
      font-size: 12px;
      font-weight: 900;
      font-family: "gilroy";
    }
  }

  .DayPicker-NavBar {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .DayPicker-NavButton {
      position: inherit;

      &--prev {
        margin-left: 20px;
        margin-right: 0;
      }

      &--next {
        margin-right: 20px;
      }
    }
  }

  .DayPicker-Day {
    padding: 8px 10px;
    font-size: 12px;

    &--today {
      color: $primary-color;
    }

    &--disabled {
      pointer-events: none;
    }

    &:hover {
      background-color: $light;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $secondary-color;
  }

  .DayPicker-Month {
    margin-top: 5px;
  }
}

/** responsive styles */
@include respondTo($md) {
  .calendar-dropdown {
    /* .classes for help dropdown animations */
    &-enter-active {
      max-height: 345px;
    }
    &-enter-done {
      max-height: 345px;
    }

    &--body {
      padding-top: 0;
    }
  }
}
