@import "bootstrap/scss/bootstrap.scss";
@import "./../assets/fonts/fonts";
@import "./../atoms/formFields/formFields.scss";
@import "./settings";
@import "./responsive";

*,
body {
  font-family: "Ambit", Arial, Helvetica, sans-serif;
}

.App {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

p {
  padding: 0;
  margin: 0;
}

.max-width {
  max-width: 1440px;
}

.nav-space {
  padding-top: 58px;

  @include respondTo($md) {
    padding-top: 50px;
  }
}

.appwide-content-area {
  min-height: 100vh;
}

.hero-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 58px;

  @include respondTo($lg) {
    margin-top: 0;
  }

  @include respondTo($xl) {
    margin-top: 0;
  }
}

.background-shade {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #010f88 0%, rgba(125, 119, 112, 0) 100%);
  mix-blend-mode: multiply;
  opacity: 0.7;
  z-index: 1;
}

.hero-image {
  width: 100vw;
  height: 165px;
  object-position: center;
  object-fit: cover;

  @include respondTo($md) {
    height: 250px;
  }

  @include respondTo($lg) {
    height: 350px;
  }

  @include respondTo($xl) {
    height: 505px;
  }
}

.common-page {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  &--content-area {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include respondTo($md) {
      width: 70%;
    }
    @include respondTo($lg) {
      width: 100%;
    }
    @include respondTo($xl) {
      width: 75%;
    }
  }
}

.color-ternary {
  color: $ternary-color !important;
}

.text-shadow {
  text-shadow: 0 0 36px #212529;
}

/** bootstrap carousal related styles */
.carousal {
  margin: -20px -30px;
}

.carousel-inner {
  padding: 20px 30px;
}

.active {
  justify-content: center;
}

.carousel-control-next {
  right: 10px;
}

.carousel-control-prev {
  left: 10px;
}

/** .end bootstrap carousal related styles */

.stepper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $primary-color;
  height: 96px;

  .stepper-width {
    width: 100%;

    @include respondTo($md) {
      width: 80%;
    }
  }
}

.title-box {
  border-bottom: 1px solid $light;
}

.form-check-input {
  cursor: pointer;
}

.flex-1 {
  display: flex;
  flex: unset;

  @include respondTo($lg) {
    flex: 1;
  }
}

.card {
  margin: 20px 0;
  border-radius: 14px;
  border: none;
  overflow: hidden;
}

.card-content {
  padding: 15px 10px;
  flex-direction: column;

  @include respondTo($md) {
    padding: 18px 20px;
  }
}

.common-card-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  color: $color-medium-gray;
  margin: 0;
}

.custom-link {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-decoration-line: underline;
  color: $secondary-color;
  padding: 0;
  margin: 0;

  &:disabled {
    color: $color-gray;
  }

  &:disabled:hover {
    color: $dark;
  }

  &:hover {
    color: $dark;
    text-decoration-line: underline;
  }

  &:focus {
    box-shadow: none;
  }
}

/** /@common static pages styles */
.opacity-change {
  opacity: 1;
  background: linear-gradient(180deg, #010f88 0%, rgba(125, 119, 112, 0.6) 100%);
}

.about-us-page {
  flex-direction: column;
  margin-top: 20px;

  &--title {
    width: 100%;
    padding: 20px 20px 0 20px;
    max-width: 1440px;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    color: $color-dark-gray;

    h1 {
      font-size: 36px;
      font-weight: 800;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
    white-space: break-spaces;
  }

  &--cards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &--card {
    margin: 10px;
    padding: 20px;
    text-align: center;
    border-radius: 14px;
    margin-bottom: 20px;
    flex: 1;

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 25px;

      img {
        width: 30%;
      }
    }

    &_desc {
      font-size: 14px !important;
      line-height: 22px !important;
    }

    h4 {
      font-size: 20px;
      line-height: 20px;
      font-weight: 800;
      margin-bottom: 20px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 800;
    text-align: center;
  }

  .go-btn {
    width: 250px;
    max-width: 250px;
    height: 60px;
    border-radius: 14px;
    font-weight: 800;
    letter-spacing: 1px;
  }
}

@include respondTo($md) {
  .about-us-page {
    margin-top: 40px;

    &--title {
      width: 75%;
      padding: 0 0 0 0;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;

      h1 {
        font-size: 48px;
        font-weight: 800;
      }

      h2 {
        font-size: 24px;
        font-weight: 600;
      }
    }

    p {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

@include respondTo($lg) {
  .about-us-page {
    &--cards-container {
      flex-direction: row;
      align-items: unset;

      &--card {
        margin-bottom: 0;
      }
    }
  }
}
