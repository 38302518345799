@import "./../../styles/settings";

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.cookies-doc-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.cookies-modal {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $color-white;
  width: 100%;
  padding: 20px 0;
  max-width: 100%;
  border-radius: 0;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  &:focus-visible {
    outline: none;
    border: none;
  }

  &-overlay {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: transparent;
    z-index: 9999;
    transition: all 800ms ease-out;
  }

  &--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    justify-content: space-between;
    width: 100%;

    &-text {
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    &-btn-box {
      display: flex;
      flex-direction: row;
    }
  }
}

@include respondTo($md) {
  .cookies-doc-modal {
    min-width: 900px;
  }

  .cookies-modal {
    padding: 30px 0;

    &--content {
      &-text {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

@include respondTo($lg) { 
  .cookies-modal {
    padding: 30px 0;

    &--content {
      flex-direction: row;

      &-text {
        font-size: 18px;
        line-height: 20px;
        text-align: left;
        margin-bottom: 0;
      }
    }
  }
}
