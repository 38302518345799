@import "./../styles/settings";

.box-shadow {
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
}

.profile-btn {
  padding: 0;
  text-align: left;
  &:focus {
    box-shadow: none;
  }
}

.show-commission-btn {
  position: relative;

  &:hover {
    &::after {
      content: "show/hide commission";
      white-space: nowrap;
      display: block;
      position: absolute;
      left: -50%;
      top: 100%;
      background-color: $color-white;
      padding: 5px 10px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px;
    }
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.top-navigation {
  width: 100%;
  position: fixed;
  z-index: 6;
  background-color: transparent;
  flex-direction: column;

  &--container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $primary-color;
    padding: 10px 0;
    box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
  }

  .nav-menu-btn {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &--extra-fill {
    display: none;
  }

  &--fill {
    width: 100%;
    height: 100%;
    background: $primary-color;
    // background: linear-gradient(63deg, $secondary-color 0%, $primary-color 100%);
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
  }

  &--content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40%;
    padding-right: 0;
    text-align: center;
    z-index: 8;
    height: 100vh;
    width: 100%;
    background-color: $primary-color;
    transform: translateX(-100vw);
    transition: all 1s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.2);
  }

  &--active-menu {
    transform: translateX(0);
  }

  .mobile-view-logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 3%;
    height: 30px;
  }

  &--colse-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .mt-80 {
    margin-top: 80px;
  }

  &--side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-dropdown {
      width: 50px;
      margin: 15px 0;
    }

    &-cart {
      position: absolute;
      top: -3px;
      right: 58px;
      order: 3;
      margin: 20px 0;

      &--count {
        display: flex;
        width: 15px;
        height: 15px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $color-LNER;
        color: $color-white;
        position: absolute;
        top: -3px;
        right: -8px;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  .logo {
    margin-right: 0;
    transition: all 0.6s;
    height: 20px;

    &--small {
      height: 20px;
    }

    &--large {
      height: 20px;
    }
  }

  .nav-link {
    padding: 0;
    margin: 20px 0;
    font-size: 14px;
    line-height: 16px;
  }
  .agency-Name {
    font-size: 12px;
    line-height: 14px;
    color: #ff6619;
    font-weight: bold;
  }

  .dark {
    .nav-link {
      color: $darker;
    }
  }

  .light {
    .nav-link {
      color: $color-white;
    }
  }

  .button-container {
    margin-left: 0;
    position: relative;
    order: 4;

    .login-btn {
      border: none;
      font-size: 10px;
      line-height: 12px;
      padding: 10px 24px;
      color: $color-white;
      background-color: $forth-color;
    }

    .nav-btn-dropdown {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: $color-white;
      max-height: 0;
      top: 40px;
      right: unset;
      left: 0;
      border-radius: 5px;
      opacity: 0;
      box-shadow: 0 5px 20px 3px rgba(0, 0, 0, 0.2);
      transition: all 500ms ease-out;

      /* .classes for help dropdown animations */
      &-enter-active {
        opacity: 1;
        max-height: 150px;
      }
      &-enter-done {
        opacity: 1;
        max-height: 150px;
      }
      &-exit-active {
        opacity: 0;
        max-height: 0;
      }
      &-exit-done {
        opacity: 0;
        max-height: 0;
      }

      p {
        color: $darker;
        padding: 15px 40px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        cursor: pointer;
        border-bottom: 1px solid $light;
        white-space: nowrap;
        overflow: hidden;

        &:first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        &:last-child {
          border: none;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &:hover {
          background-color: $secondary-color;
        }
      }
    }
  }
}

@include respondTo($lg) {
  .top-navigation {
    &--container {
      height: unset;
      background-color: transparent;
      padding: 0 0;
      box-shadow: none;
    }

    &--content {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      height: 100%;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
      z-index: 8;
      background-color: transparent;
      transform: translateX(0);
      position: relative;
      top: 0;
      box-shadow: none;
    }

    &--extra-fill {
      display: flex;
    }

    .mt-80 {
      margin-top: 0;
    }

    &--side {
      flex-direction: row;
      align-items: center;
      margin-left: 0;

      &-dropdown {
        margin: 0 0;
      }

      &-cart {
        position: relative;
        order: 0;
        right: 0;
        margin: 0 0;
        margin-right: 20px;
      }
    }

    .logo {
      margin-right: 20px;

      &--small {
        height: 30px;
      }

      &--large {
        height: 50px;
      }
    }

    .nav-link {
      padding: 0;
      margin: 0 16px;
    }

    .dark {
      .nav-link {
        color: $darker;
      }
    }

    .light {
      .nav-link {
        color: $color-white;
      }
    }

    .button-container {
      margin-left: 16px;

      .login-btn {
        border: none;
        font-size: 10px;
        line-height: 12px;
        padding: 10px 24px;
      }
    }
  }
}
@include respondTo($xl) {
  .top-navigation {
    &--content {
      width: 77%;
    }
  }
}
