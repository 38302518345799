@import './../../styles/settings';

.custom-borderd-box {
  display: flex;
  border: 1px solid $light;
  padding: 12px 15px;
  width: 100%;
  border-radius: 10px;

  legend {
    font-size: 1em;
    max-width: fit-content;
    margin: 0;
  }
}