@import "./../../styles/settings";

.popular-journey-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $light-background;
  padding: 30px 0;

  &--content-area {
    margin-top: 0;
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      line-height: 35px;
      font-size: 19px;
      color: $color-medium-gray;
      text-align: center;
    }
  }
}

.mv-50 {
  margin: 20px 0;
}
.mb-100 {
  margin-bottom: 100px;
}
.h1-text{
text-align: center;
}
.popular-card-text{
  text-align: center !important;
}
/** popular journey card styles */
.popular-journey-card {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 25px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  background-color: $color-white;

  &:hover {
    box-shadow: 0 10px 25px 0px rgba(15, 171, 110, 0.4);
    cursor: pointer;
    background-color: $forth-color;

    p,
    span {
      color: $color-white;
    }
  }

  &--content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    border-radius: 6px;

    p {
      font-size: 20px;
      line-height: 23px;
      color: $color-medium-gray;

      span {
        font-weight: 900;
        color: $color-dark-gray;
      }
    }
  }
}
/** .end popular journey card styles */

@include respondTo($md) {
  .popular-journey-section {
    padding: 0 0;

    &--content-area {
      margin-top: 45px;
      width: 70%;
    }
  }

  .mv-50 {
    margin: 50px 0;
  }
}


@import "./../../styles/settings";

.saved-search {
  border-radius: 14px;
  overflow: hidden;
  max-height: calc(100vh - 390px);
  height: 100%;
  min-height: 300px;
  padding-bottom: 15px;

  &--inner {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--header {
    padding: 15px;
    border-bottom: 1px solid $light;
  }

  &--title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 800;
    margin: 0;
  }

  &--item {
    display: flex;
    width: 100%;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin: 8px 0;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: $color-green;

      p {
        color: $color-white;
      }
    }

    &-icon {
      position: relative;
      top: -3px;
      margin: 0 10px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      font-weight: 800;
      color: $color-medium-gray;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 45%;
      min-width: 30%;
    }
  }
}

