@import "./../../styles/settings";

.booking-confirmation {
  padding: 10px 15px 45px 15px;

  &--print-button,
  &--share-button {
    position: absolute;
    top: 20px;
    right: 30px;
    padding: 0;
  }

  &--share-button {
    right: 70px;
    top: 18px;
  }

  .logo {
    height: 55px;
    width: 50%;
  }

  &--send-button {
    width: 100%;
    min-width: 100%;
    height: 55px;
    margin-left: 0;
    font-weight: 400;
  }

  &--regards {
    padding: 20px 10px 40px 10px;
    border: 1px solid $light;
  }

  &--email-input {
    display: flex;
  }

  &--summary {
    margin: 0 0 10px 0;

    &-title {
      display: flex;
      padding: 5px 15px;
      border-bottom: 3px solid $color-green;
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 12px 15px;
      justify-content: space-between;
      align-items: flex-start;
      background-color: $color-light-green;

      div {
        margin: 8px 0;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 12px 15px;
      border: 2px solid $color-light-green;
      border-top: none;

      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin: 10px 0;
      }

      p {
        line-height: 18px !important;
      }
    }

    &-total {
      color: $color-darker-gray;
    }

    &-amount {
      font-size: 22px;
      line-height: 24px;
      font-weight: 800;
      color: $primary-color;
    }
  }

  .billing-info,
  .payment-details {
    display: flex;
    padding: 12px 0;
    flex-direction: column;

    &--values {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      p:first-child {
        line-height: 16px;
        color: $primary-color !important;
      }
    }

    &--title {
      width: 100%;
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;

      p {
        color: $color-green !important;
      }
    }

    &--single-payment {
      border-bottom: 1px solid $light;
      padding-bottom: 15px;
      margin-bottom: 25px;
    }
  }

  h2 {
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    margin: 15px 0;
  }

  h3 {
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
    color: $color-medium-gray;

    span {
      color: $primary-color;
    }
  }

  h4 {
    width: 100%;
    padding: 8px;
    background-color: $secondary-color;
    color: $color-white;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: 0;
  }

  p {
    line-height: 25px;

    span {
      color: $color-green;
    }
  }

  .cents {
    color: inherit;
    font-size: 12px;
    line-height: 14px;
    vertical-align: text-top;
  }
}

@include respondTo($xl) {
  .booking-confirmation {
    padding: 0 45px 45px 45px;

    &--print-button {
      right: 10%;
    }

    &--share-button {
      right: 15%;
    }

    &--button {
      top: 10px;
      right: 8%;
    }

    &--regards {
      padding: 20px 20px 40px 20px;
    }

    &--summary {
      &-content {
        flex-direction: row;
        align-items: center;

        div {
          margin: 0 0;
        }
      }

      &-footer {
        flex-direction: row;
        align-items: flex-end;

        div {
          margin: 0 0;
          align-items: flex-start;
        }
      }
    }

    .billing-info,
    .payment-details {
      padding: 12px 15px;

      &--values {
        flex-direction: row;
        margin-bottom: 0;

        p:first-child {
          line-height: 25px;
          width: 25%;
        }
      }
    }
  }
}

@media print {
  .booking-confirmation {
    padding: 45px 60px 0 45px;

    &--regards {
      padding: 20px 20px 40px 20px;
    }

    &--email-input {
      display: none;
    }

    &--summary {
      &-content {
        flex-direction: row;
        align-items: center;

        div {
          margin: 0 0;
        }
      }

      &-footer {
        flex-direction: row;
        align-items: center;

        div:first-child {
          margin-right: 40px !important;
          align-items: flex-start;
        }
      }
    }

    .billing-info,
    .payment-details {
      padding: 12px 15px;

      &--values {
        flex-direction: row;
        margin-bottom: 0;

        p:first-child {
          line-height: 25px;
          width: 25%;
        }
      }
    }
  }
}
