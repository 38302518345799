
/** color variables */
$color-white: #fff;
$color-apply-gray: #3C3C3C;
$color-fb-blue: #376CD7;
$color-gray: #9A9A9A;
$color-dark-gray: #252525;
$color-darker-gray: #151515;
$color-medium-gray: #6F6F6F;
$color-varient-gray: #E6E6E6;
$color-lighter-gray: #C4C4C4;
$color-light-green: #DFF2EA;
$color-green: #0fab6e;
$color-dark-green: #077C4E;
$color-light-green: #D7F9EC;
$color-pink: #CD2593;
$color-red: #EC4949;
$color-light-red: #FF7171;
$color-dark-yellow:  #E8B633;
$color-light-blue: #D2EAFF;
$orange: #f39a51;
$black: #000000;

$light-background: #FAFBFF;
$primary-color: #3D3DBD;
$secondary-color: #8080FF;
$ternary-color: #FDFF05;
$forth-color: #FF6619;
$light: #B3AAFF;
$lighter: #E9F3FF;
$dark: #070033;
$gray: #333333;
$dark-blue: #000e3280;
$darker: #2a4170;
$lighter-background: #ECF0F6;
$landing-page-background: #7878f1;
$lighter-orange: #ffeee5;

$color-LNER: #C30D0D;

/* Extra small devices (phones, 600px and down) */
$xs: 320px;
/* Small devices (portrait tablets and large phones, 600px and up) */
$sm: 600px;
/* Medium devices (landscape tablets, 768px and up) */
$md: 768px;
/* Large devices (laptops/desktops, 992px and up) */
$lg: 992px;
/* Extra large devices (large laptops and desktops, 1200px and up) */
$xl: 1200px;
/* 2K, 4K devices (large laptops and desktops, 2500px and up) */
$xxl: 2500px;

@mixin respondTo($brack-point) {
    @media only screen and (min-width: $brack-point) {
        @content;
    }
}

@mixin respondUpTo($break-point) {
    @media only screen and (max-width: $break-point) {
        @content;
    }
}