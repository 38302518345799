@import "./../../styles/settings";

.alert-banner-container {
  height: 35px;
}

.alert-banner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: $secondary-color;
  position: relative;
  z-index: 9;
  height: 40px;

  &--button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    padding: 3px 7px;
    z-index: 10;

    &:hover {
      background-color: $light;
    }
  }

  p,
  &--link {
    color: $color-white;
    font-size: 14px;
  }

  &--link {
    font-weight: 600;
    &:hover {
      color: $color-white;
      text-decoration: underline;
    }
  }
}
