@import "./../../../styles/settings";

.intermediate-points {
  display: flex;
  flex-direction: column;
  padding: 0 60px 30px 45px;
  min-width: 800px;

  &--content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &::before {
      content: "";
      display: flex;
      position: absolute;
      width: 4px;
      background-color: $color-gray;
      border-radius: 4px;
      top: 26px;
      bottom: 35px;
      left: 10px;
    }

    &::after {
      content: "";
      display: flex;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $color-gray;
      border-radius: 50%;
      bottom: 30px;
      left: 7px;
    }
  }

  &--date {
    font-size: 18px;
    line-height: 20px;
    color: $color-medium-gray;
    font-weight: 800;
  }

  &--service {
    display: flex;
    align-items: center;

    p {
      color: $color-green;
    }
  }

  .ml-25 {
    margin-left: 25px;
  }

  &--start,
  &--end {
    display: flex;
    margin-left: 25px;
  }

  &--end {
    margin-top: 20px;
  }

  .dark-small,
  .dark-big {
    font-size: 20px;
    line-height: 22px;
    color: $color-darker-gray;
    font-weight: 800;
  }

  .dark-small {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  .light {
    color: $color-lighter-gray;
  }

  .bold {
    font-weight: 800;
  }

  .arrow-icon {
    position: relative;
    top: -5px;
    margin: 0 6px;
  }

  &--step {
    display: flex;
    position: relative;
    margin-left: 25px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 10px;
      left: -19px;
      background-color: $color-white;
      border: 3px solid $color-gray;
    }

    &-left {
      margin: 10px 25px;
      text-align: right;
    }

    &-right {
      margin: 10px 0;
    }
  }
}

@include respondUpTo($sm) {
  .intermediate-points {
    padding: 0 0 30px 3px !important;
    min-width: 450px;
    width: 425px;

    
    &-ml-5 {
      margin-left: 5px;
    }

    &--date {
      width: 300px;
    }

    &--content {

      &::before {
        top: 30px
      }
    }

    &--service {
      width: 410px;
      height: 35px;
    }

    &--point-title {
      width: 270px;
    }
  }
}