/* #### Generated By: http://www.cufonfonts.com #### */
// @font-face {
//     font-family: "gilroy";
//     font-style: normal;
//     font-weight: 100;
//     src: local("Gilroy-Light"), url("Gilroy-Light.woff") format("woff");
//     font-display: swap;
// }

// @font-face {
//     font-family: "gilroy";
//     font-style: normal;
//     font-weight: 400;
//     src: local("Gilroy-Regular"), url("Gilroy-Regular.woff") format("woff");
//     font-display: swap;
// }

// @font-face {
//     font-family: "gilroy";
//     font-style: normal;
//     font-weight: 600;
//     src: local("Gilroy-Medium"), url("Gilroy-Medium.woff") format("woff");
//     font-display: swap;
// }

// @font-face {
//     font-family: "gilroy";
//     font-style: normal;
//     font-weight: 800;
//     src: local("Gilroy-Bold"), url("Gilroy-Bold.woff") format("woff");
//     font-display: swap;
// }

// @font-face {
//     font-family: "gilroy";
//     font-style: normal;
//     font-weight: 900;
//     src: local("Gilroy-Heavy"), url("Gilroy-Heavy.woff") format("woff");
//     font-display: swap;
// }




@font-face {
    font-family: 'Ambit';
    src: url('Ambit-Light.woff2') format('woff2'),
        url('Ambit-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ambit';
    src: url('Ambit-LightItalic.woff2') format('woff2'),
        url('Ambit-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ambit';
    src: url('Ambit-Italic.woff2') format('woff2'),
        url('Ambit-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ambit';
    src: url('Ambit-Regular.woff2') format('woff2'),
        url('Ambit-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ambit';
    src: url('Ambit-Bold.woff2') format('woff2'),
        url('Ambit-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ambit';
    src: url('Ambit-BoldItalic.woff2') format('woff2'),
        url('Ambit-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

