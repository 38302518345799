@import "./../../styles/settings";

.copy-btn,
.save-btn {
  position: relative;
  padding: 3px;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      white-space: nowrap;
      background-color: $color-white;
      border-radius: 5px;
      padding: 5px 10px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.copy-btn {
  &:hover {
    &::after {
      content: 'Copy Itinerary';
    }
  }
}

.save-btn {
  &:hover {
    &::after {
      content: 'Save Fav';
    }
  }
}
.delete-btn {
  &:hover {
    &::before {
      content: 'Delete Search ';
    }
  }
}

.trip-detail-card {
  &--type,
  &--service {
    font-size: 14px;
    line-height: 16px;
    font-weight: 900;
  }

  &--type {
    margin-right: 12px;
    font-size: 20px;
    line-height: 20px;
  }

  &--date {
    font-size: 15px;
    line-height: 18px;
    color: $color-darker-gray;
    margin-bottom: 7px;
    font-weight: 900;
  }

  &--station {
    color: $color-gray;
    margin-bottom: 7px;
  }

  &--servie-box {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &--service {
    color: $color-LNER;
  }

  &--days-count {
    color: $color-green;
    font-size: 12px;
    line-height: 14px;
    margin-top: 12px;
  }

  &--hour-count {
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
    color: $color-green;
    margin-left: 10px;
  }

  &--amount-box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 14px;
      line-height: 18px;
    }

    h3 {
      text-align: right;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.02em;
      font-weight: 900;
      color: $color-green;
      margin: 0;
    }

    h4 {
      text-align: right;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.02em;
      font-weight: 900;
      color: $color-green;
      margin: 0;
    }
  }

  &--gst-price {
    text-align: right;
    line-height: 23px;
  }
}

.f-18 {
  font-size: 18px !important;
}

@include respondUpTo($md) {
  .trip-detail-card {
    &--gst-price {
      text-align: right;
      line-height: 23px;
    }
  }

}

.trip-detail-sleeper-font {
  font-weight: 800 !important;
}