@import "./../styles/settings";
.hero-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 58px;
}

.image-box {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

.background-shade {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #010f88 0%, rgba(125, 119, 112, 0) 100%);
  mix-blend-mode: multiply;
  opacity: 0.7;
  z-index: 1;
}

.hero-image {
  width: 100vw;
  height: 165px;
  object-position: center;
  object-fit: cover;
}

.search-box {
  width: 90%;
  position: relative;
  top: -55px;
  left: 0;
  z-index: unset;
}

.change-color {
  color: $color-white !important;
}

.center-text {
  position: absolute;
  color: $color-white;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  h1 {
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  h2 {
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
  h3 {
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

@include respondTo($md) {
  .search-box {
    top: 0;
    transform: translate(0, -20%);
    z-index: 2;
  }

  .hero-image {
    height: 250px;
  }

  .date-box {
    width: 25%;
  }

  .center-text {
    top: 40%;

    h1 {
      font-weight: 900;
      font-size: 25px;
      line-height: 27px;
    }

    h2 {
      font-size: 16px;
      line-height: 18px;
    }
    h3 {
      font-weight: 900;
      font-size: 25px;
      line-height: 27px;
    }
  }
}

/** .journy Data styles */
.dates-array {
  display: flex;
  width: 100%;
}
.btn-right-arrow .btn-default {
  color: rgb(104, 26, 26) !important;
}
.date-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 33.33%;

  &--day {
    font-size: 14px !important;
    line-height: 16px !important;
    margin-bottom: 5px;
    color: $color-white;
  }

  &:last-child {
    .date-box--content {
      border-right: 1px solid $color-varient-gray;
    }
  }

  .prev-dates {
    background-color: $lighter-background;
  }

  &--content {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    height: 150px;
    border: 1px solid $color-varient-gray;
    border-right: none;
    background-color: $color-white;
    cursor: pointer;

    p {
      font-size: 14px;
      line-height: 16px;
      position: absolute;
      right: 10px;
      top: 10px;
      color: $color-gray;
    }

    h6 {
      margin: 0;
      font-size: 20px;
      line-height: 1;
      font-weight: 600;
      color: $color-darker-gray;
      margin-right: 8px;

      span {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
}
.date-box-click:hover {
  text-decoration: underline;
  color: rgb(11, 46, 242);
}
.article {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.image-shading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($dark-blue, 0.5);
}

.image-holder {
  position: relative;
  width: 100%;
  height: 400px;
}
.image {
  object-position: top;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.header {
  font-size: 80px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
  text-shadow: 2px 2px #212529;
}
/** .end journy Data styles */

@include respondTo($lg) {
  .search-box {
    top: 0;
    transform: translate(0, -50%);
  }

  .date-box {
    width: calc(100% / 7);
  }

  .hero-image {
    height: 350px;
  }

  .hero-image-container {
    margin-top: 0;
  }
}

@include respondTo($xl) {
  .hero-image-container {
    margin-top: 0;
  }

  .hero-image {
    height: 505px;
  }

  .search-box {
    width: 70%;
  }

  .center-text {
    top: 50%;

    h1 {
      font-size: 45px;
      line-height: 53px;
    }

    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 28px;
    }
    h3 {
      font-size: 45px;
      line-height: 53px;
    }
  }
}
.min-height {
  min-height: 100vh;
}

.table-container-trainTimeTable {
  display: flex;
  flex-direction: column;
  width: 100%;

  table {
    width: 100%;

    thead {
      background-color: $primary-color;

      tr {
        th {
          padding: 16px 20px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 800;
          letter-spacing: 1px;
          color: $color-white;
          text-align: left;

          &:first-child {
            border-top-left-radius: 14px;
          }

          &:last-child {
            border-top-right-radius: 14px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $light;

        &:last-child {
          border-bottom: none;
        }

        td {
          padding: 16px 10px;
          text-align: left;

          font-size: 15px;
          line-height: 18px;
          font-weight: 800;

          .actions-box {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            button {
              &:focus {
                box-shadow: none;
              }
            }

            .other-buttons {
              padding: 0;
            }
          }
        }
      }

      .selected-row {
        border-bottom: none;
        td {
          color: $primary-color;
        }
      }

      .selected-utility-btn {
        background-color: $primary-color;
      }

      .disabled {
        color: darkgrey;
      }
    }
  }
}

.color-green {
  color: $color-green;

  &::before {
    content: "";
    position: relative;
    top: 1px;
    display: inline-flex;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background-color: $color-green;
  }
}

.color-red {
  color: $color-red;

  &::before {
    content: "";
    position: relative;
    top: 1px;
    display: inline-flex;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background-color: $color-red;
  }
}

.add-new-button {
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;
  border-radius: 10px;
  height: 60px;
  width: 100%;
}
.row-click-tr {
  cursor: pointer;
  text-align: left;
}
.row-click-tr:hover {
  text-decoration: underline;
  color: rgb(11, 46, 242);
}

.delete-agent-modal {
  padding: 0 60px 30px 50px;
  min-width: 800px;

  &--button-box {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid $light;
    padding: 10px 20px;
  }
}

@include respondUpTo($md) {
  .dates-array {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 10px;
    text-align: center;
  }
}
