@import "./../styles/settings";

.contact-info {
  font-size: 18px;
  line-height: 1;
  margin-left: 5px;
}
.contact-info-NZ {
  font-size: 18px;
  line-height: 1;
  margin-left: 70px;
}

.register-form {
  min-width: 100%;
  box-sizing: border-box;
  padding: 0 15px 60px 15px;

  .name-fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
}
.register-form-model {
  @extend .register-form;
  padding: 0 15px 15px 15px;
}

.create-agency {
  &--title {
    font-weight: 800;
    text-align: center;
  }

  &--back-btn {
    position: absolute;
    top: 5px;
    left: 15px;
  }
}
.custom-file-upload {
  cursor: pointer;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 15px;
  padding-left: 120px;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  font-size: 14px;
  line-height: 16px;
  color: $color-lighter-gray;
  position: relative;
  overflow: hidden;

  label {
    display: flex;
    padding: 5px 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
  }

  &::before {
    content: "Choose File";
    display: flex;
    align-items: center;
    position: absolute;
    background-color: #ced4da;
    color: $color-dark-gray;
    width: 110px;
    padding-left: 15px;
    bottom: 0;
    left: 0;
    top: 0;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
  }

  span {
    display: block;
    color: $color-dark-gray;
    position: relative;
    max-width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-gray;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &:hover {
      background-color: $color-lighter-gray;
    }
  }
}

.ml-10 {
  margin-left: 0;
}

.sub-titile {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  color: $color-medium-gray;
  // padding-bottom: 3px;
  // border-bottom: 1px solid $color-lighter-gray;
}

.country-dropdown {
  border: 1px solid #ced4da;
  padding: 26px 20px;
  height: calc(1.5em + 1rem + 2px);
  border-radius: 10px;
  margin-left: 0;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    font-weight: 400;
  }
}

.form-button-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 32px;

  .footer-text {
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    margin-top: 14px;
  }
}

.sign-up,
.sign-in {
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  padding: 0 15px 60px 15px;

  &--submit-button {
    width: 100%;
    height: 55px;
    max-width: 100%;
    min-width: 250px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.13em;
  }

  &--secondary-button {
    width: 100%;
    height: 55px;
    font-size: 14px;
    line-height: 16px;
    max-width: 100%;
    font-weight: 400;
    margin-bottom: 12px;
  }

  &--font-change {
    font-weight: 800;
    letter-spacing: 0.13em;
  }

  .forget-password {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .custom-link {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $primary-color;
      }
    }
  }

  .custom-check {
    width: 100%;
    margin-top: 10px;

    .custom-check-input {
      margin-top: 2px;
    }

    .custom-label {
      color: $color-medium-gray;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .question-bold {
    font-size: 15px;
    line-height: 18px;
    font-weight: 900;
    color: $color-dark-gray;
    margin-bottom: 16px;
  }
}

@include respondTo($md) {
  .register-form {
    min-width: 450px;

    .name-fields {
      flex-direction: row;
    }
  }

  .ml-10 {
    margin-left: 10px;
  }

  .sign-up,
  .sign-in {
    width: 450px;
    min-width: 450px;

    &--submit-button {
      width: 60%;
    }

    &--font-change {
      font-weight: 800;
      letter-spacing: 0.13em;
    }

    .custom-check {
      width: 70%;
    }
  }
}

@include respondTo($lg) {
  .register-form {
    min-width: 588px;
    padding: 0 45px 60px 45px;
  }
  .register-form-model {
    padding: 15px 45px 15px 45px;
  }
  .sign-up,
  .sign-in {
    padding: 0 45px 60px 45px;
  }
}
