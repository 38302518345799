@import "./../../../styles/settings";

.rt-selection {
  padding: 0 20px 40px 20px;
  min-width: 100vw;

  &--btn {
    display: flex;
    width: 100%;
    padding: 0;
    margin-top: 15px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid $light;

    &:hover {
      border: 1px solid $primary-color;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    &:focus {
      border: 1px solid $primary-color;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &--item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    box-sizing: border-box;
    width: 100%;

    &-career {
      text-align: left;
      color: $color-LNER;
      font-weight: 900;
    }

    &-staions {
      p {
        font-size: 16px;
        line-height: 18px;
        color: $color-gray;
        font-weight: 800;
      }
    }

    &-time-difference {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: #d7f9ec;
      align-self: flex-end;
      border-radius: 4px;
      padding: 10px;

      &::before {
        content: "";
        display: flex;
        position: absolute;
        height: 1px;
        background-color: $color-green;
        left: 25px;
        right: 90px;
      }

      p {
        color: $color-green;
        font-size: 14px;
        line-height: 16px;
        font-weight: 800;
        margin-left: 6px;
        position: relative;
        top: 1px;
      }
    }

    &-amount {
      font-size: 16px !important;
      line-height: 18px !important;
      color: $primary-color;
      font-weight: 800;
      text-align: right;
    }
  }
}

@include respondTo($md) {
  .rt-selection {
    min-width: 70vw;
    padding: 0 20px 40px 20px;

    &--item {
      &-career {
        text-align: center;
      }
      &-time-difference {
        justify-content: center;
      }
    }
  }
}

@include respondTo($lg) {
  .rt-selection {
    min-width: 50vw;
    padding: 0 60px 40px 45px;
  }
}
