@import "./../styles/settings";

.footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: $primary-color;
  height: auto;
  position: relative;
  padding-top: 20px;

  .footer-link {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: $color-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px 40px 15px;
    border-bottom: 1px solid $color-white;
  }

  .content-column {
    display: flex;
    flex-direction: column;
    color: $color-white;

    h3, h4 {
      font-weight: 800;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    ul {
      padding-inline-start: 0;

      li {
        list-style-type: none;
        padding: 3px 0;

        a {
          font-weight: 400;
          color: $color-white;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .social-media-box {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .icon-box {
      padding-right: 40px;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .pay-options-box {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .icon-box {
      padding-right: 15px;

      &:last-child {
        padding-right: 0;
      }

      .payment-icons {
        border: 2px solid$color-white;
        border-radius: 10px;
      }

      .avoid-border {
        border: none !important;
      }
    }
  }

  .copyrights {
    display: flex;
    height: 80px;
    align-items: center;
    padding: 0 15px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $color-white;
      text-align: center;
    }
  }
}

@include respondTo($md) {
  .footer {
    &--content {
      flex-direction: row;
      padding: 20px;
    }
  }
}

@include respondTo($lg) {
  .footer {
    height: 380px;
    padding-top: 0;

    &--content {
      width: 70%;
      padding: 0 0 40px 0;
    }

    .content-column {
      flex-direction: column;

      h4 {
        font-weight: 900;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 14px;
      }

      ul {
        padding-inline-start: 0;

        li {
          padding: 10px 0;

          a {
            font-size: 19px;
            line-height: 22px;
          }
        }
      }
    }

    .copyrights {
      p {
        font-size: 14px;
        line-height: 16px;
        text-align: left;
      }
    }
  }
}
