.loader {
  width: 100vw;
  height: 100vh;
  font-size: 20px;
  background-color: rgba($color: #fff, $alpha: 0.8);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  .content {
    width: 10%;
    align-items: center;
  }
}

.train {
  width: 3em;
  height: 3em;
  background: radial-gradient(circle at 23% 80%, currentColor 0.4em, transparent 0.4em),
    radial-gradient(circle at 77% 80%, currentColor 0.4em, transparent 0.4em), #bbb;
  border-radius: 0.5em;
  position: relative;
  color: #444;
  animation: train-animate 1.5s infinite ease-in-out;

  &::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 0.8em;
    background-color: currentColor;
    border-radius: 0.2em;
    top: 0.7em;
    left: 10%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 25%;
    height: 0.2em;
    background-color: currentColor;
    border-radius: 0.1em;
    top: 0.3em;
    left: calc((100% - 25%) / 2);
  }
}

@keyframes train-animate {
  0%,
  100% {
    transform: rotate(0deg);
  }

  25%,
  75% {
    transform: rotate(0.5deg);
  }

  50% {
    transform: rotate(-0.5deg);
  }
}

.track {
  width: 5.5em;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0.3em;
    height: 2.5em;
    background-color: #bbb;
    border-radius: 0.4em;
    transform-origin: bottom;
  }
  &::before {
    left: 0;
    transform: skewX(-35deg);
  }

  &::after {
    right: 0;
    transform: skewX(35deg);
  }
}

.track span {
  width: inherit;
  height: 0.3em;
  background-color: #bbb;
  position: absolute;
  top: 4em;
  animation: track-animate 1s linear infinite;
}

.track span:nth-child(2) {
  animation-delay: -0.33s;
}

.track span:nth-child(3) {
  animation-delay: -0.66s;
}

@keyframes track-animate {
  0% {
    transform: translateY(-2em) scaleX(1);
    filter: opacity(0);
  }

  10%,
  90% {
    filter: opacity(1);
  }

  100% {
    transform: translateY(-4em) scaleX(0.45);
    filter: opacity(0);
  }
}
