@import "./../../styles/settings";

.expandable-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 25px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  background-color: $color-white;
  border-radius: 14px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  &--expand-button-container {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  &--expand-button {
    font-size: 10px;
    line-height: 12px;
    background-color: $color-green;
    color: $color-white;
    display: flex;
    align-items: center;
    padding: 5px 8px;

    &-icon {
      margin-left: 5px;
    }

    &:hover {
      background-color: lighten($color-green, 5);
      color: $color-white;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &-selected {
    border-left: 15px solid $forth-color;
    border-radius: 14px;
  }

  &--content {
    padding: 10px 15px;
    border-radius: 14px;
    transition: all 500ms ease-out;

    &:hover {
      background-color: lighten($forth-color, 40%);
    }

    &-active {
      z-index: 1;
      box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0 0 ;
    }
  }

  &--drawer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 0;
    background-color: $color-white;
    padding: 18px 20px;
    border-radius: 0 0 14px 14px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 500ms ease-out;

    /* .classes for help dropdown animations */
    &-enter-active {
      max-height: 320px;
      padding: 18px 10px;

      @include respondTo($md) {
        padding: 18px 20px;
      }
    }
    &-enter-done {
      max-height: 320px;
      padding: 18px 10px;

      @include respondTo($md) {
        padding: 18px 20px;
      }
    }
    &-exit-active {
      max-height: 0;
      padding: 0 20px;
    }
    &-exit-done {
      max-height: 0;
      padding: 0 20px;
    }
  }

  &--header {
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    min-height: 36px;
    margin-top: 25px;
    // border-bottom: 1px solid $light;

    @include respondTo($md) {
      margin-top: 0;
      align-items: center;
    }
  }

  &--button {
    min-width: 43px;
    height: 43px;
    background: transparent;
    border: 2px solid $light;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 10px;

    &:focus {
      box-shadow: none;
    }

    &-active {
      background-color: $primary-color;
      border: none;
    }
  }
}

.overflow-unset{
  overflow: unset;
}