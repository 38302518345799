@import "./../../styles/settings";

.utility-button {
  background-color: $lighter-background;
  line-height: 14px;
  border-radius: 9px;

  &:hover {
    background-color: darken($lighter-background, 10%);
  }
}

.small {
  padding: 7px 10px;
}

.medium {
  padding: 7px 10px;
}

.large {
  padding: 7px 10px;
}

@include respondTo($md) {
  .large {
    padding: 10px 13px;
  }
}
