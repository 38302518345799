@import "./../../../styles/settings";

.faq{
  h3{
    font-size: 18px !important;
    font-weight: 900 !important;
    margin-top: 40px !important;
  }
p{
  font-size: 18px !important;
  font-weight: 100 !important;
}
}
.text-bold{
  font-weight: 700 !important;
}
.heading{
  h2{
    font-size: 28px !important;
    font-weight: 900 !important;
  }
}
.content{
  h2{
    font-size: 18px !important;
  }
}
