@import "./../../styles/settings";

.form-field {
  margin-bottom: 12px;
  width: 100%;
  position: relative;
}

.form-label {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
}
.form-control-lg {
  padding: 26px 20px;
  border-radius: 10px;
}

.form-control {
  font-size: 14px;
  line-height: 16px;
}

.form-control::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: $color-lighter-gray;
}

.error {
  color: $color-red;
  font-size: 12px;
  line-height: 14px;
  margin: 5px;
}
.showHidePassword{
position: absolute;
  right: 15px;
  top:63%;
  transform: translateY(-50%);
}