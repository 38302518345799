@import "./../../styles/settings";

.detail-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &--aside {
    display: flex;
    flex-direction: column;

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: $color-medium-gray;
      margin: 0;
      margin-bottom: 5px;
      padding: 0;
    }
  }

  &--calendar-time {
    display: flex;
  }

  .w-50 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .w-40 {
    width: 100%;
    max-width: 100%;
  }

  .left-border {
    border-left: none;
    padding-left: 0;
    border-top: 1px solid $light;
    padding-top: 10px;
    margin-top: 10px;
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: $lighter;
    margin: 0;
    border-radius: 4px;
    height: 32px;
    cursor: pointer;
    align-self: flex-end;

    &::before {
      content: "";
      background-color: $light;
      display: flex;
      height: 1px;
      width: calc(100% - 80px);
      position: absolute;
      left: 15px;
    }
  }
}

/** .responsive styles */
@include respondTo($md) {
  .detail-box {
    flex-direction: row;

    .w-50 {
      width: 50%;
      max-width: 50%;
    }

    .w-40 {
      width: 45%;
      max-width: 42%;
    }

    .left-border {
      border-left: 1px solid $light;
      padding-left: 15px;
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }

    &--icon {
      margin: 0 10px 0 5px;
      align-self: flex-start;

      &::before {
        display: none;
      }
    }
  }
}
