@import "./../../styles/settings";

.custom-dropdown {
  display: flex;
  position: relative;
  max-width: 100%;

  &--btn {
    max-width: 100%;
    padding: 0;
    background-color: transparent;
    color: $color-medium-gray;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 0;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:disabled {
      opacity: 1;
      outline: none;
      pointer-events: none;
    }

    &:focus {
      box-shadow: none;
    }

    .btn-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  &--input {
    margin-left: 0px;
    padding: 5px 15px 5px 5px;
    text-align: left;
    height: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    border: none;
    cursor: pointer;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;

    &::placeholder {
      font-weight: 800;
      font-size: 12px;
      line-height: 14px;
    }

    &:focus {
      border: 1px solid $light;
      box-shadow: 0 3px 8px $light;
    }

    &:disabled,
    &[readonly] {
      background-color: transparent;
    }
  }

  &--mobile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px;
    border-bottom: 1px solid $light;

    h1 {
      font-size: 18px;
      line-height: 20px;
      font-weight: 800;
      margin: 0;
      color: $color-medium-gray;
    }
  }

  .arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;

    .closeIcon{
      outline: none;
      border: none;
      margin: 0;
      border-radius: 50%;
      background-color: #9cdbff;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .closeIcon{
    outline: none;
    border: none;
    margin: 0;
    border-radius: 50%;
    background-color: #9cdbff;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not-bold {
    font-weight: 400;
  }

  .selected {
    background-color: $light;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }

  .mobile-input {
    padding: 10px 5px;
    margin-left: 0;
    border: 1px solid $light;
    box-shadow: 0 3px 8px $light;
  }

  &--body {
    top: 0;
    position: fixed;
    width: auto;
    min-width: 100vw;
    max-height: 100vh;
    min-height: 100vh;
    overflow: hidden;
    background-color: $color-white;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 7;
    opacity: 0;
    transition: all 500ms ease-out;

    &-inner-box {
      width: 100%;
      height: 100vh;
      margin-top: 60px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    /* .classes for help dropdown animations */
    &-enter-active {
      opacity: 1;
      max-height: 100vh;
    }
    &-enter-done {
      opacity: 1;
      max-height: 100vh;
    }
    &-exit-active {
      opacity: 0;
      max-height: 0;
    }
    &-exit-done {
      opacity: 0;
      max-height: 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      color: $color-medium-gray;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      border-bottom: 1px solid $light;
      padding: 12px 15px;
      place-content: space-between;

      &:hover {
        cursor: pointer;
        background-color: $secondary-color;
        color: $color-white;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

/** responsive styles */
@include respondTo($md) {
  .custom-dropdown {
    &--mobile-title {
      display: none;
    }

    &--body {
      top: 30px;
      position: absolute;
      width: auto;
      min-width: 250px;
      max-height: 250px;
      min-height: 0;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 5;

      &-inner-box {
        margin-top: 0;
        height: 100%;
      }

      /* .classes for help dropdown animations */
      &-enter-active {
        max-height: 250px;
      }
      &-enter-done {
        max-height: 250px;
      }
    }
  }
}

@include respondTo($lg) {
  .custom-dropdown {
    &--input {
      margin-left: -5px;
    }
  }
}
