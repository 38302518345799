@import "./../styles/settings";

.xs-text {
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  font-weight: 400;
}

.z-index {
  z-index: 2;
}

.line-through {
  text-decoration: line-through;
}

.mobile-description-text {
  font-weight: 800;
  white-space: nowrap;
}

.black-text {
  color: $color-darker-gray;
}

.refund-btn,
.exchange-btn {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  padding: 20px 50px;
  width: max-content;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin-top: 15px;
}

.pagination-item {
  display: flex;
  border: 1px solid $light;
  border-right: none;
  cursor: pointer;

  &:last-child {
    border-right: 1px solid $light;
  }

  &:hover {
    background-color: $color-lighter-gray;
  }
}

.pagination-link {
  padding: 3px 10px;

  &:hover {
    text-decoration: none;
  }
}

.prev-btn {
  padding: 3px 10px;
  color: $primary-color;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.next-btn {
  padding: 3px 10px;
  color: $primary-color;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.active-page {
  background-color: $primary-color;

  a {
    color: $color-white;
  }
}

.my-bookings {
  margin-top: 60px;

  .search-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    width: 100%;
    height: 135px;
    border-radius: 0;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 16px;
    z-index: 2;

    &--title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 900;
      color: $color-medium-gray;
      margin-bottom: 20px;
    }

    .search-input {
      display: flex;
      flex: 1;

      &--form-field {
        height: 60px;
      }

      &--button {
        font-size: 14px;
        line-height: 16px;
        font-weight: 900;
        border-radius: 10px;
        height: 60px;
        width: 100%;
      }
    }

    .visibility-hidden {
      visibility: visible;
      opacity: 0;
      height: 0;
      position: absolute;
    }
  }

  &--card {
    overflow-x: auto;
    border-radius: 14px;
    margin-top: 14px;
  }

  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 60px;
    width: 100%;
  }

  .selected-utility-btn {
    background-color: $primary-color;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    table {
      width: 850px;

      thead {
        background-color: $primary-color;

        tr {
          th {
            padding: 16px 20px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 800;
            letter-spacing: 1px;
            color: $color-white;
            text-align: center;

            &:first-child {
              border-top-left-radius: 14px;
            }

            &:last-child {
              border-top-right-radius: 14px;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $light;

          &:last-child {
            border-bottom: none;
          }

          td {
            padding: 16px 10px;
            text-align: center;

            font-size: 15px;
            line-height: 18px;
            font-weight: 800;
          }
        }

        .selected-row {
          td {
            color: $primary-color;
          }
        }

        .disabled {
          color: darkgrey;
        }
      }
    }
  }
}

.cross-mark {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}

/** refund / exchange modal styles */
.exchange-modal-container {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  position: relative;
}

.refund-modal,
.exchange-modal {
  padding: 0 15px 25px 15px;

  &--scroll-box {
    position: relative;
    overflow: auto;
    height: calc(100% - 158px);
    min-height: 500px;
  }

  &--header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid $light;
    padding: 30px 10px;

    &-desc {
      width: 100%;
    }

    &-detail-box {
      width: 100%;
      margin-top: 12px;
    }
  }

  &--item {
    padding: 15px;

    &:first-of-type {
      margin-top: 25px;
    }

    &:nth-child(odd) {
      background-color: $light;
    }
  }

  &--content-box {
    padding: 0 10px;
    padding-bottom: 0;

    &-row {
      border-bottom: 1px solid $color-green;
      padding-bottom: 20px;
    }
  }

  &--title {
    font-size: 15px;
    line-height: 18px;
    font-weight: 800;
    color: $color-dark-gray;
  }

  &--amount {
    font-size: 22px;
    line-height: 26px;
    font-weight: 900;
    color: $color-green;
    letter-spacing: 0.02em;
  }

  &--content {
    font-size: 14px;
    line-height: 22px;
    font-weight: 800;
    color: $color-medium-gray;
  }

  &--small-text {
    font-size: 10px;
    line-height: 12px;
  }

  &--button-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $light;
    padding: 10px 15px;
  }

  &--button,
  &--search-button {
    padding: 20px 40px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    width: 100%;
  }

  &--search-button {
    padding: 25px 40px;
    align-self: flex-end;
  }
}

.exchange-modal {
  &--button-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $light;
    padding: 10px 15px;
    position: relative;
    bottom: 0;
    right: 0;
    background-color: white;
    width: 100%;
    z-index: 7;
  }
}

/** .end refund / exchange modal styles */

/** booking details view styles */
.booking-details {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 20px 20px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-white;
  z-index: 9;
  overflow: auto;

  &--close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    display: block;
  }

  &--heading {
    display: flex;
    align-items: center;
    width: 100%;

    &-title {
      font-size: 20px;
      line-height: 20px;
      font-weight: 900;
      margin: 0;
      text-align: left;
    }

    .small-buttons {
      min-width: 180px;
      width: 180px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      padding: 10px 16px;
      margin-left: 30px;
      margin-top: 10px;
      border-radius: 8px;

      &:last-child {
        margin-left: 12px;
      }
    }
  }

  &--content {
    display: block;
    justify-content: space-between;
    margin-top: 10px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      padding: 20px 10px;
      border-top: 1px solid $light;
    }

    &-conditions {
      font-size: 12px;
      line-height: 14px;
    }

    .mobile-amount-box {
      border-top: 1px solid $light;
      padding: 20px;
    }

    &-amount-box {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }

    &-amount {
      font-size: 25px;
      line-height: 29px;
      letter-spacing: 0.02em;
      font-weight: 900;
      margin: 0;
      margin-top: 10px;
    }
  }

  &--passenger-box {
    margin-top: 50px;
    flex-direction: column;

    &-table {
      display: none;

      thead {
        background-color: transparent !important;

        tr {
          th {
            padding: 0 20px !important;
            font-size: 14px !important;
            line-height: 16px !important;
            font-weight: 400 !important;
            color: $color-gray !important;
            border-radius: 0 !important;
          }
        }
      }
    }

    &-mobile {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
    }
  }
}

/** .end booking details view styles */

@include respondTo($md) {
  .pagination-link {
    padding: 5px 15px;
  }

  .prev-btn {
    padding: 5px 15px;
  }

  .next-btn {
    padding: 5px 15px;
  }

  .my-bookings {
    .search-container {
      position: absolute;
      width: 80%;
      height: 135px;
      border-radius: 14px;
      top: 50%;
      z-index: 2;

      .search-input {
        display: flex;
        flex: 1;

        &--form-field {
          height: 60px;
        }

        &--button {
          font-size: 14px;
          line-height: 16px;
          font-weight: 900;
          border-radius: 10px;
          height: 60px;
        }
      }
    }

    .table-container {
      table {
        width: 100%;
      }
    }

    &--card {
      overflow-x: hidden;
    }
  }

  /** refund / exchange modal styles */

  .refund-modal{
    width: 100%;
  }

  .refund-modal,
  .exchange-modal {
    padding: 0 60px 45px 45px;

    &--scroll-box {
      height: calc(100% - 100px);
    }

    &--header {
      padding: 30px 40px;
    }

    &--content-box {
      padding: 0 30px;
    }

    &--button-box {
      flex-direction: row;
      padding: 15px 40px;
    }

    &--button,
    &--search-button {
      width: auto;
    }
  }

  .exchange-modal {
    &--button-box {
      position: absolute;
    }
  }

  /** .end refund / exchange modal styles */

  /** booking details view styles */
  .booking-details {
    height: auto;
    position: relative;
    padding: 20px 40px;
    z-index: 1;

    &--close-btn {
      display: none;
    }

    &--heading {
      .small-buttons {
        margin-top: 0;

        &:last-child {
          margin-left: 8px;
        }
      }
    }

    &--content {
      margin-top: 30px;

      &-item {
        border-top: none;
        padding: 0 12px;
      }

      .mobile-amount-box {
        border-top: none;
        padding: 0;
      }
    }
  }

  /** .end booking details view styles */
}

@include respondTo($lg) {
  .my-bookings {
    margin-top: 0;
    .search-container {
      width: 70%;
    }
  }

  /** refund / exchange modal styles */
  .refund-modal{
    width: auto;
  }
  .refund-modal,
  .exchange-modal {
    &--content-box {
      padding-bottom: 130px;

      &-row {
        border-bottom: none;
      }
    }
  }

  /** .end refund / exchange modal styles */

  /** booking details view styles */
  .booking-details {
    &--passenger-box {
      &-table {
        display: table;
      }

      &-mobile {
        display: none;
      }

      &--content {
        display: flex;

        &-amount-box {
          width: 60%;
        }
      }
    }
  }

  /** .end booking details view styles */
}

@include respondTo($xl) {
  .my-bookings {
    .table-container {
      width: 80%;
    }
  }

  /** refund / exchange modal styles */
  .refund-modal,
  .exchange-modal {
    &--scroll-box {
      height: 100%;
    }

    &--header {
      padding: 30px 40px;
      flex-direction: row;
      align-items: center;

      &-desc {
        width: 50%;
      }

      &-detail-box {
        width: 500px;
        margin-top: 0;
      }
    }
  }

  /** .end refund / exchange modal styles */

  /** booking details view styles */
  .booking-details {
    &--content {
      &-item {
        padding: 0 0;
      }

      &-amount-box {
        width: 40%;
      }
    }
  }

  /** .end booking details view styles */
}
