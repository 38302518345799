@import "./../../styles/settings";

.time-dropdown {
  /* .classes for help dropdown animations */
  &-enter-active {
    opacity: 1;
    max-height: 100vh;
  }
  &-enter-done {
    opacity: 1;
    max-height: 100vh;
  }

  &--body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 100%;

    &__item {
      width: 100%;
      justify-content: center;
    }
  }
}

/** responsove styles */
@include respondTo($md) {
  .time-dropdown {
    /* .classes for help dropdown animations */
    &-enter-active {
      max-height: 250px;
    }
    &-enter-done {
      max-height: 250px;
    }

    &--body {
      width: 120px;
      min-width: 120px;
    }
  }
}
