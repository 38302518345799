@import "./../../styles/settings";

.checkout-form {
  display: flex;
  flex-direction: column;
  padding: 0 10px 20px 10px;
}

.example.example1 {
  background-color: #ffffff;
  margin-top: 10px;
}

.example.example1 fieldset {
  margin: 15px 0;
  border-style: none;
  background-color: #fff;
  border-radius: 4px;
}

.example.example1 .card-number-field {
  border: 1px solid #ced4da;
  border-radius: 10px;
  padding: 9px 10px;
}

.example.example1 .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

.example.example1 .row + .row {
  border-top: 1px solid red;
}

.saved-card {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid $light;
  border-radius: 14px;
  cursor: pointer;
  margin-bottom: 10px;

  .radio-box {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin: 8px 0;
    width: auto;

    &--radio {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      padding: 2px;
      width: 15px;
      height: 15px;
      border: 2px solid $primary-color;
      border-radius: 100%;
      cursor: pointer;
    }
  }

  &--details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    p {
      font-size: 1em;

      &:first-child {
        margin-bottom: 5px;
      }
    }
  }

  .active-radio {
    background-color: $primary-color;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.selected-card {
  border: 2px solid $primary-color;
  box-shadow: 0 0 8px 2px rgba(0, 100, 217, 0.2);
}

.example.example1 .StripeElement--webkit-autofill {
  background: transparent !important;
}

.example.example1 .StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.example.example1 button:active {
  background-color: #0064d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #5a88b8;
}

.error-msg {
  color: $color-red;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
}

.example.example1 .error svg .base {
  fill: #fff;
}

.example.example1 .error svg .glyph {
  fill: #6772e5;
}

.example.example1 .error .message {
  color: #fff;
}

.example.example1 .success .icon .border {
  stroke: #87bbfd;
}

.example.example1 .success .icon .checkmark {
  stroke: #fff;
}

.example.example1 .success .title {
  color: #fff;
}

.example.example1 .success .message {
  color: #9cdbff;
}

.example.example1 .success .reset path {
  fill: #fff;
}

@include respondTo($md) {
  .checkout-form {
    padding: 0 25px 20px 25px;
  }

  .saved-card {
    padding: 5px 20px;

    .radio-box {
      &--radio {
        margin-right: 15px;
      }
    }

    &--details {
      p {
        font-size: 1.1em;

        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }
}
