@import "./../styles/settings";

.destinations {
  .eurail {
    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 100;
    }

    .sub-title {
      font-size: 20px;
      line-height: 22px;
      font-weight: 900;
      margin-bottom: 10px;
    }

    .content-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 100;

    @include respondTo($lg) {
      font-size: 18px;
      line-height: 32px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
  }

  .wd-75 {
    width: 90%;
  }

  .hero-image-container {
    margin-top: 50px;
  }

  .bg-patch {
    width: 100%;
    height: 1280px;
    background: $landing-page-background;
  }

  .bg-patch-eurail {
    width: 100%;
    background: $landing-page-background;
  }

  .image-box {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
  }

  .banner-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(58, 58, 58, 0.38);
    mix-blend-mode: multiply;
  }

  .search-box {
    width: 90%;
    position: relative;
    top: -55px;
    left: 0;
    z-index: unset;
  }

  .sub-title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 900;
    margin-bottom: 25px;
  }

  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bg-light {
    background-color: $color-light-blue !important;
  }

  .bg-bright {
    background-color: $lighter-orange !important;
  }

  .content-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .prices-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 20px;
      text-align: center;
      color: $color-white;
    }
  }

  .prices-ribbon {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: $color-white;
      border: 0.5px solid $primary-color;
      padding: 40px 0;
      width: 33.33%;

      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 18px;

        span {
          font-size: 12px;
          line-height: 18px;
        }

        &:hover {
          text-decoration: underline;
          color: $primary-color !important;
          cursor: pointer;
        }
      }

      .date {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 12px;
        line-height: 14px;
        color: $color-gray;
      }
    }
  }

  .center-text {
    width: 100%;
    position: absolute;
    color: $color-white;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    h1 {
      font-weight: 800;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }

    h2 {
      font-weight: 100;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }
  }

  @include respondTo($md) {
    .search-box {
      top: 6%;
      transform: translate(0, -20%);
      z-index: 2;
    }

    .bg-patch {
      height: 1050px;
    }

    .center-text {
      top: 40%;

      h1 {
        font-weight: 900;
        font-size: 25px;
        line-height: 27px;
      }

      h2 {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .prices-ribbon {
      .box {
        width: 25%;

        &:last-child {
          border-right: none;
        }

        p {
          font-size: 20px;
          line-height: 24px;

          span {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .date {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }

  @include respondTo($lg) {
    .wd-75 {
      width: 75%;
    }

    .bg-patch {
      height: 800px;
    }

    .search-box {
      top: 15%;
      transform: translate(0, -50%);
    }

    .prices-ribbon {
      .box {
        width: calc(100% / 7);
        padding: 45px 0;
      }
    }

    .sub-title {
      font-size: 28px;
      line-height: 30px;
    }
  }

  @include respondTo($xl) {
    .search-box {
      width: 70%;
    }

    .bg-patch {
      height: 1000px;
    }

    .prices-box {
      h3 {
        font-size: 32px;
      }
    }

    .center-text {
      top: 40%;

      h1 {
        font-weight: 900;
        font-size: 48px;
        line-height: 59px;
      }

      h2 {
        font-size: 19px;
        line-height: 22px;
      }
    }
  }
}
