@import "./../../styles/settings";

.height-90vh {
  height: 90vh !important;
  overflow: auto;
}

.custom-label {
  color: $color-medium-gray;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
}

.search-edit {
  display: flex;
  width: 100%;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  height: auto;
  z-index: 7;
  background: $color-white;
  // background: linear-gradient(63deg, $secondary-color 0%, $primary-color 100%);
  padding-bottom: 0;

  &--content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 100%;

    &--check-boxes {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .filter-edit {
    display: flex;
    width: 100%;
    box-shadow: none;
    align-items: center;
    justify-content: center;
    height: auto;
    z-index: 7;
    background-color: $color-white;
    padding-bottom: 0;
  
    &--content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      height: 100%;
  
      &--check-boxes {
        position: relative;
        top: 30px;
        left: 0;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  

  .disable-via {
    pointer-events: none;
    opacity: 0.6;
  }

  .border-box {
    display: flex;
    padding: 10px 20px;
    border-radius: 14px;
    border: 1px solid $light;
    align-items: center;

    .dropdown {
      width: 70%;

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $color-medium-gray;
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
      }
    }
    .w-30 {
      width: 27%;
      margin-right: 3%;
      border-right: 1px solid $light;
    }
  }

  .radio-box {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin: 8px 0;
    width: auto;

    &--radio {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      padding: 2px;
      width: 15px;
      height: 15px;
      border: 2px solid $primary-color;
      border-radius: 100%;
      cursor: pointer;
      top: -1px;
    }

    .radio-label {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
  }

  .disable-radio {
    pointer-events: none;
    border-color: $color-gray;
  }

  .disable-active-radio {
    background-color: $color-gray !important;
  }

  .destination-box,
  .calendar-box,
  .passenger-box,
  .button-box,
  .country-box,
  .railpass-box {
    padding: 10px;
    box-sizing: border-box;
  }

  .destination-box {
    width: 100%;
    // margin-top: 65px;
  }

  .calendar-box {
    width: 100%;
  }

  .passenger-box {
    width: 50%;
    flex: 1;
    display: flex;
  }

  .country-box {
    width: 100%;
  }

  .railpass-box {
    width: 100%;
  }

  .button-box {
    width: 50%;
    flex: 1;

    .button-text-chage {
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      padding: 20px 10px;
    }
  }
}

.search-box-w-filter {
  display: flex !important;
  flex-wrap: wrap !important;

}

@include respondTo($md) {
  .height-90vh {
    height: auto !important;
    overflow: unset;
  }

  .search-edit {
    .destination-box {
      margin-top: 35px;
    }
  }

  // .height-90 {
  //   height: 90px !important;

  //   & .destination-box,
  //   & .calendar-box,
  //   & .passenger-box,
  //   & .button-box,
  //   & .country-box,
  //   & .railpass-box {
  //     margin-top: 0;
  //   }
  // }
}

@include respondTo($lg) {
  .search-edit {
    box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;

    &--content {
      width: 96%;
      flex-direction: row;

      &--check-boxes {
        position: absolute;
      }

      .filter-edit {
        box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
        padding-bottom: 10px;
    
        &--content {
          width: 96%;
          flex-direction: row;
    
          &--check-boxes {
            position: absolute;
          }
        }
      }
    }

    .calendar-box,
    .passenger-box,
    .button-box,
    .country-box,
    .railpass-box {
      padding: 0;
      padding-left: 10px;
      margin-top: 60px;
    }

    .railpass-button-box {
      margin-top: 0 !important;
    }

    .destination-box {
      width: 33%;
      padding: 0;
      margin-top: 60px;
    }

    .calendar-box {
      width: 42%;
    }

    .passenger-box {
      width: 10%;
    }

    .country-box {
      width: 35%;
    }

    .railpass-box {
      width: 50%;
    }

    .button-box {
      width: 15%;

      .button-text-chage {
        font-size: 14px;
        font-weight: 800;
        line-height: 16px;
      }
    }
  }
}

@include respondTo($xl) {
  .search-edit {
    &--content {
      width: 75%;
      flex-direction: row;
    }
  }
}
