@import "./../../styles/settings";

.passenger-dropdown {
  /* .classes for help dropdown animations */
  &-enter-active {
    opacity: 1 !important;
    max-height: 100vh !important;
  }
  &-enter-done {
    opacity: 1 !important;
    max-height: 100vh !important;
  }

  &--body {
    min-width: 100vw !important;
    padding-top: 60px;
  }

  &--inner-box {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .passenger-dropdown-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid $light;

    &--content {
      display: flex;
      width: 100%;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
    }

    &--input-container {
      padding: 5px 12px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }

    &--form-field {
      width: 50%;
    }

    &--select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      cursor: pointer;
      margin: 5px 0;

      &:focus {
        box-shadow: 0 0 5px 2px $light;
      }
    }

    .w-70 {
      width: 70%;
    }

    .w-30 {
      width: 30%;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: 900;
      font-size: 12px;
      color: $color-dark-gray;
      line-height: 14px;
    }

    .desc {
      color: $color-medium-gray;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
    }

    .count {
      color: $color-green;
      margin: 0 10px;
    }

    .age-field-error {
      border-color: $color-red;
      box-shadow: 0 0 0 0.2rem rgba(236, 73, 73, 0.3);
    }

    &--btn {
      background-color: $color-light-green;
      color: $color-green;
      padding: 0;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 20px;

      &:disabled {
        background-color: $color-lighter-gray;
        color: $color-darker-gray;
      }
    }

    &--left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &--right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

/** responsive styles */
@include respondTo($md) {
  .passenger-dropdown {
    /* .classes for help dropdown animations */
    &-enter-active {
      max-height: 345px !important;
    }
    &-enter-done {
      max-height: 345px !important;
    }

    &--body {
      min-width: 300px !important;
      padding-top: 0;
    }
  }
}
