@import "./../../styles/settings";

.expandable-pTop,
.expandable-rail-pass {
  &--title {
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    color: $color-medium-gray;
    margin: 0 15px;
    top: 6px;
    position: relative;
  }

  &--content {
    .list-item {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 7px;

      .bullet {
        position: relative;
        top: 2px;
        display: flex;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-lighter-gray;
        margin-right: 10px;
      }

      p {
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $color-medium-gray;
      }
    }
  }

  &--service {
    font-size: 15px;
    line-height: 18px;
    font-weight: 900;
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &--type {
    font-size: 12px;
    line-height: 14px;
    font-weight: 800;
  }

  &--desc {
    font-size: 12px;
    line-height: 12px;
    margin-top: 2px;
  }

  &--service {
    color: $color-LNER;
    margin-left: 10px;
  }

  &--time-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    &::before {
      content: "";
      display: flex;
      position: absolute;
      width: 4px;
      background-color: $color-gray;
      border-radius: 4px;
      top: 0;
      bottom: 0;
      left: 20px;
    }

    &-content {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: -1px;
        left: 1px;
        background-color: $color-white;
        border: 3px solid $color-gray;
      }

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        &::before {
          background-color: $color-gray;
        }
      }
    }
  }

  &--time {
    display: flex;
    align-items: center;
    width: 36%;

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 800;
      margin-left: 5px;
    }
  }

  &--station {
    display: flex;
    p {
      font-size: 12px;
      line-height: 14px;
      font-weight: 800;
      color: $color-gray;
      margin-left: 5px;
    }
  }

  &--time-difference-box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
  }

  &--time-difference {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-light-green;
    padding: 15px 12px;

    p {
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      font-weight: 800;
      color: $color-green;
    }
  }

  &--amount-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--amount-box {
    padding: 0 12px 0 0;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
  }

  &--amount {
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $primary-color;
    font-weight: 900;
  }

  &--border-box {
    padding: 7px 25px;
  }

  &--drawer-seperator {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-green;
    margin: -5px;

    &--header {
      padding: 5px 10px;
      background-color: $color-green;

      p {
        font-weight: 800;
        color: $color-white;
      }
    }

    &--content {
      padding: 5px 10px;
    }
  }

  &--drawer-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0;
    border-bottom: 1px solid $light;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &--amount {
      font-size: 15px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $primary-color;
      font-weight: 800;
    }
  }

  &--drawer-footer {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;

    &--title,
    &--content {
      font-size: 12px;
      line-height: 14px;
      color: $color-medium-gray;
    }

    &--title {
      font-weight: 900;
    }
  }
}

.expandable-rail-pass {
  &--drawer-item:last-child {
    border-bottom: none;
  }
}

.expandable-rail-pass--drawer-item .radio-box {
  width: auto;
}

.radio-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;

  &--radio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    padding: 2px;
    width: 15px;
    height: 15px;
    border: 2px solid $primary-color;
    border-radius: 100%;
    cursor: pointer;
    top: -2px;
  }

  &--amount {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0;
    color: $primary-color;
    font-weight: 800;
  }

  .active-radio {
    background-color: $primary-color;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

/** responsive changes */
@include respondTo($lg) {
  .expandable-pTop {
    &--time {
      p {
        font-size: 16px;
      }
    }
  }

  .expandable-rail-pass {
    &--time-box {
      flex-direction: row;
      align-items: center;

      &-content {
        max-width: 40%;
      }
    }

    &--service {
      width: 80%;
    }

    &--time-difference-box {
      margin: 0 0;
      width: unset;
    }

    &--time-difference {
      &::before {
        display: none;
      }
    }

    &--drawer-item {
      flex-direction: row;
      align-items: center;

      &--amount {
        font-size: 25px;
        line-height: 25px;
        font-weight: 900;
      }
    }
  }

  .expandable-rail-pass--drawer-item .radio-box {
    width: 25%;
  }

  .expandable-rail-pass > .radio-box {
    width: 35%;
    margin-top: 0;

    &--radio {
      margin-right: 15px;
      padding: 3px;
      width: 25px;
      height: 25px;
      border-width: 4px;
      top: 0;
    }

    &--amount {
      font-size: 25px;
      line-height: 25px;
      letter-spacing: 0.02em;
      font-weight: 900;
    }
  }
}
